const colors = {
  /* semantic palette */
  primary: "#1C253E",
  primaryLight: "#3A4B69",
  accent: "#C79200",
  text: "#1C253E",

  // greys
  grey: "#6F707B",
  greyLight: "#8C8E9E",
  greyLightest: "#F7F7F7",
  greyBorder: "#F3F3F5",
  greySecondaryBorder: "#B9BCD1",

  // variants
  blue: "#437AA3",
  red: "#AA4215",
  green: "#487C59",
  greenLight: "#AEB7AE",
  salmon: "#F4A366",

  white: "#fff",

  /* applied colors */
  htmlBackgroundColor: "#fff",
  bodyBackgroundColor: "#fff",
  secondaryBackgroundColor: "#E2E2E6",
  // $selection-color: lighten($color-primary, 85%);
  // $disabled-color: $gray-light;
};
export default colors;
