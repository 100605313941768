import styled from "styled-components";
import { breakpoints } from "../../theme";

export const TooltipWrapper = styled.div`
  font-size: 0;
  margin-left: 2rem;
`;
export const Wrapper = styled.div`
  margin: 3rem 0;
`;
export const TitleWrapper = styled.div`
  display: flex;
  position: relative;
  margin-left: 33rem;

  p {
    margin-bottom: 0;
  }

  ${breakpoints.max.lg} {
    margin-left: 40%;
  }
  ${breakpoints.max.md} {
    margin-left: 0;
  }
`;
export const Content = styled.div`
  > :last-child {
    margin-bottom: 0;
  }
`;
