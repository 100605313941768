import styled, { css } from "styled-components";
import inputStyles from "../../theme/inputStyles";
import { Field } from "formik";
import { EInputSize } from "../../types";
import MaskedInput from "react-text-mask";

const inputStylesWithProps = css<{ disabled?: boolean; size?: EInputSize }>`
  ${inputStyles};

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.7;
    cursor: not-allowed;
  `}

  ${(props) =>
    props.size === EInputSize.Small &&
    `
    width: 17rem;
  `}

  ${(props) =>
    props.size === EInputSize.Large &&
    `
      width: 45rem;
    `}
`;

export const StyledInput = styled(Field)`
  ${inputStylesWithProps};
`;

export const StyledMaskedInput = styled(MaskedInput)`
  ${inputStyles};
`;
