export enum EInputType {
  Checkbox = "checkbox",
  Email = "email",
  Number = "number",
  File = "file",
  Hidden = "hidden",
  Radio = "radio",
  Select = "select",
  Tel = "tel",
  Text = "text",
  TextArea = "textarea",
}

export interface IOption {
  label: string;
  tooltip?: string;
}
