import React, { useCallback, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import api from "../config/api";
import {
  Box,
  Text,
  Container,
  FieldContainer,
  Input,
  Button,
  SmallSection,
} from "../components";
import colors from "../theme/colors";
import {
  ButtonVariant,
  EContainerVariant,
  EInputType,
  ETextVariant,
  IProfile,
} from "../types";
import { Form, Formik } from "formik";
// import api from "../config/api";

import { getRIBURL, getFakeRIB } from "../helpers/rib";

const CONFIRM_ERROR = "Une erreur est survenue. Merci de nous contacter.";

interface Props {
  profile: IProfile;
  paymentErrorMessage: string | null;
  setPaymentErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
  isPaymentSuccess: boolean;
  setIsPaymentSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  clientSecret: string | null;
}
const PaymentForm: React.FC<Props> = ({
  profile,
  paymentErrorMessage,
  setPaymentErrorMessage,
  clientSecret,
  setIsPaymentSuccess,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (values: any, bag: any) => {
    setPaymentErrorMessage(null);
    api.logStripePresubmit({ ...values });

    if (!(stripe && elements && clientSecret)) return null;

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) return null;

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });
    // setIsLoading(false);

    if (payload.error && payload.error.message) {
      api.logStripeError({ ...values, payload: JSON.stringify(payload) });
      setPaymentErrorMessage(payload.error.message);
      return null;
    }

    const res = await api.confirmPayment({
      ...values,
      payload: JSON.stringify(payload),
    });
    const { success = false } = res?.data;
    if (success) {
      setIsPaymentSuccess(true);
    } else {
      setPaymentErrorMessage(CONFIRM_ERROR);
    }
    bag.setSubmitting(false);
  };

  const alertUser = useCallback(
    (e) => {
      if (clientSecret) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
    [clientSecret]
  );

  // Alert user if he try to leave the page
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [alertUser]);

  return (
    <Formik
      initialValues={
        { ...profile, amount_with_currency: profile.amount + "€" } || {}
      }
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <FieldContainer name="society_denomination" label="Société">
            <Input
              name="society_denomination"
              type={EInputType.Text}
              disabled
            />
          </FieldContainer>
          <FieldContainer name="entity_denomination" label="Entité">
            <Input name="entity_denomination" type={EInputType.Text} disabled />
          </FieldContainer>
          <FieldContainer name="amount_with_currency" label="Montant">
            <Input
              name="amount_with_currency"
              type={EInputType.Text}
              disabled
            />
          </FieldContainer>
          {clientSecret ? (
            <>
              <SmallSection title="Payer par carte">
                <div
                  style={{
                    padding: "2rem",
                    backgroundColor: colors.white,
                  }}
                >
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: "16px",
                          padding: "10px",
                          color: colors.primary,
                          "::placeholder": {
                            color: "#aab7c4",
                          },
                        },
                        invalid: {
                          color: colors.red,
                        },
                      },
                    }}
                  />
                </div>
              </SmallSection>
              {paymentErrorMessage && (
                <Container variant={EContainerVariant.Small}>
                  <Text variant={ETextVariant.Body2} color={colors.red}>
                    {paymentErrorMessage}
                  </Text>
                </Container>
              )}
              <br />
              <br />
              <Box jc="center">
                <Button
                  variant={ButtonVariant.Primary}
                  disabled={formik.isSubmitting || !stripe}
                  isLoading={formik.isSubmitting || !stripe}
                  type="submit"
                >
                  Payer par carte
                </Button>
              </Box>

              <SmallSection title="Payer par virement">
                <Text color={colors.white}>
                  Indiquer dans votre libellé votre SIREN et le nom de
                  l'association à laquelle vous souhaitez adhérer sous le format
                  suivant : <i>{getFakeRIB(profile.entity_denomination)}</i>
                </Text>
                <Text color={colors.white}>
                  <a
                    href={getRIBURL(profile.entity_denomination)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cliquer ici pour télécharger l'IBAN
                  </a>
                </Text>
              </SmallSection>
            </>
          ) : (
            <>
              <br />
              <Text center>Aucune cotisation à payer.</Text>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default PaymentForm;
