const axios = require("axios").default;

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 120000,
});

const initPayment = (data) => instance.post(`/init`, data);
const confirmPayment = (data) => instance.post(`/confirm`, data);
const logStripePresubmit = (data) =>
  instance.post(`/log/stripe-presubmit`, data);
const logStripeError = (data) => instance.post(`/log/stripe-error`, data);

const api = {
  initPayment,
  confirmPayment,
  logStripeError,
  logStripePresubmit,
};

export default api;
