export enum EInputSize {
  Small = "small",
  Regular = "regular",
  Large = "Large",
}

export enum ERepeaterButtonVariant {
  Large = "large",
  Small = "small",
}

export enum ETextVariant {
  Title1 = "Title1",
  Title2 = "Title2",
  Title3 = "Title3",
  Title4 = "Title4",
  Body1 = "Body1",
  Body2 = "Body2",
}

export enum EFieldContainerVariant {
  Default = "default",
  OptionDefault = "option-default",
  Small = "small",
  TextArea = "textarea",
  File = "file",
  FullWidth = "full-width",
  BigLabel = "big-label",
}

export enum ESectionVariant {
  Primary = "primary",
  Secondary = "secondary",
  NoPaddingBottom = "no-padding-bottom",
}
export enum ESectionSize {
  FullWidth = "full-width",
  Half = "half",
}
export enum EContainerVariant {
  FullWidth = "full-width",
  Small = "small",
  Medium = "medium",
  Large = "large",
}
export enum ButtonVariant {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Quaternary = "quaternary",
}
