import React from "react";
import { ESectionSize, ESectionVariant, ETextVariant } from "../../types";
import {
  Content,
  TitleWrapper,
  TooltipWrapper,
  Wrapper,
} from "./styled-components";
import { Tooltip, Text, Container } from "../../components";

interface Props {
  title?: string;
  tooltip?: string;
  children: React.ReactNode;
  variant?: ESectionVariant;
  size?: ESectionSize;
  titleVariant?: ETextVariant.Title1 | ETextVariant.Title3;
}

const Section: React.FC<Props> = ({
  title = "",
  children,
  tooltip = "",
  variant = ESectionVariant.Primary,
  size = ESectionSize.FullWidth,
  titleVariant = ETextVariant.Title1,
}) => {
  const ConditionalContainer =
    size === ESectionSize.FullWidth ? Container : "div";
  return (
    <Wrapper size={size}>
      <ConditionalContainer>
        <Content withTitle={!!title} variant={variant}>
          {title && (
            <TitleWrapper titleVariant={titleVariant}>
              <Text variant={titleVariant}>{title}</Text>
              {tooltip ? (
                <TooltipWrapper>
                  <Tooltip>{tooltip}</Tooltip>
                </TooltipWrapper>
              ) : null}
            </TitleWrapper>
          )}
          {children}
        </Content>
      </ConditionalContainer>
    </Wrapper>
  );
};

export default Section;
