import React from "react";
import { useTheme } from "styled-components";
import { EIconNames, ETextVariant } from "../types";
import { BigSection, Text } from "../components";
import { colors } from "../theme";

interface Props {
  color?: string;
}
const SubHeader: React.FC<Props> = ({ color = colors.primary }) => {
  const theme = useTheme();
  return (
    <BigSection color={color} icon={EIconNames.Clock}>
      <Text variant={ETextVariant.Title3} color={theme.colors.primary}>
        Bienvenue sur la page de réglement des cotisations
      </Text>
      <Text variant={ETextVariant.Body1}>
        Le montant de vos cotisations est calculé en fonction des informations
        fournies pour la société et l'entité ci dessous. Merci de vérifier
        l'exactitudes de celles-ci.
        <br />
        Pour toute information, merci de nous contacter via notre&nbsp;
        <a href="https://www.​cncef.​org/contact/">formulaire de contact</a>.
      </Text>
    </BigSection>
  );
};

export default SubHeader;
