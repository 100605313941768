export const getRIBURL = (entity: string) => {
  let key: string | null | undefined = null;
  switch (entity) {
    case "CNCEF":
      key = "iban-cncef.pdf";
      break;
    case "CNCEF Patrimoine":
      key = "iban-cncef-patrimoine.pdf";
      break;
    case "CNCEF Crédit":
      key = "iban-cncef-credit.pdf";
      break;
    case "CNCEF Immobilier":
      key = "iban-cncef-immobilier.pdf";
      break;
    case "CNCEF Assurance":
      key = "iban-cncef-assurance.pdf";
      break;
    case "CNCEF France M&A":
      key = "iban-cncef-france.pdf";
      break;
  }
  return process.env.REACT_APP_DOCUMENT_URL + "/" + key;
};

export const getFakeRIB = (entity: string) => {
  let key: string | null | undefined = null;
  switch (entity) {
    case "CNCEF":
      key = "CNCEF";
      break;
    case "CNCEF Patrimoine":
      key = "PATRIMOINE";
      break;
    case "CNCEF Crédit":
      key = "CREDIT";
      break;
    case "CNCEF Immobilier":
      key = "IMMOBILIER";
      break;
    case "CNCEF Assurance":
      key = "ASSURANCE";
      break;
    case "CNCEF France M&A":
      key = "FRANCE_MA";
      break;
  }
  return "123456789_" + key;
};
