import styled from "styled-components";
import colors from "../../theme/colors";

export const Wrapper = styled.div`
  margin-top: 5rem;
`;
export const TitleWrapper = styled.div`
  display: flex;
  position: relative;
`;
export const Content = styled.div`
  padding: 4rem;
  background-color: ${colors.primary};
  color: ${colors.white};
`;
