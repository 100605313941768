import styled from "styled-components";
import { ErrorMessage } from "formik";
import { colors, fonts, breakpoints } from "../../theme";
import { EFieldContainerVariant } from "../../types";

export const Wrapper = styled.div<{ variant: EFieldContainerVariant }>`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;

  ${(props) =>
    props.variant === EFieldContainerVariant.File &&
    `
      margin: 0;
      padding: 3rem 0;
      position:relative;

      &:before {
        position: absolute;
        z-index: 0;
        left: -3rem;
        bottom: 0;
        content: "";
        display: block;
        width: calc(100% + 6rem);
        height: 0.1rem;
        background-color: ${colors.greySecondaryBorder};
        margin-right: 1.5rem;
      }
    `}

  ${(props) =>
    props.variant === EFieldContainerVariant.BigLabel &&
    `
      margin-top: 2.5rem;
    `}

  ${breakpoints.max.md} {
    margin-left: 0;
    flex-wrap: wrap;
  }
`;
export const Label = styled.label<{ variant: EFieldContainerVariant }>`
  font-size: ${fonts.size.m};
  font-weight: 700;
  color: ${colors.text};
  margin: 0;
  line-height: 1.2;

  a {
    color: ${colors.text};
  }

  ${(props) =>
    props.variant === EFieldContainerVariant.BigLabel &&
    `
      font-weight: 400;
	`}
`;

export const LabelWrapper = styled.div<{ variant: EFieldContainerVariant }>`
  display: flex;
  flex: 0 0 33rem;
  margin-top: 0;
  padding-right: 2.4rem;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  max-width: 40%;

  ${(props) =>
    props.variant === EFieldContainerVariant.BigLabel &&
    `
    align-self: flex-start;
    text-align: left;
    flex: 0 0 45rem;
    max-width: 70%;
	`}

  ${(props) =>
    props.variant === EFieldContainerVariant.Small &&
    `
    flex: 0 0 auto;
    max-width: 100%;
	`}

  ${(props) =>
    props.variant === EFieldContainerVariant.OptionDefault &&
    `
    margin-top: 1rem;
    align-self: flex-start;
	`}

  ${(props) =>
    props.variant === EFieldContainerVariant.FullWidth &&
    `
    padding-right: 0;
    flex: 0 0 0;
	`}

  ${(props) =>
    props.variant === EFieldContainerVariant.TextArea &&
    `
    margin-top: 1.3rem;
    align-self: flex-start;
	`}

  ${(props) =>
    props.variant === EFieldContainerVariant.File &&
    `
    text-align: left;
    justify-content: flex-start;
    flex: 0 0 auto;
    width: calc(100% - 25rem);
    max-width: 100%;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      display: block;
      flex: 0 0 0.5rem;
      width: 0.5rem;
      height: 0.5rem;
      background-color: ${colors.red};
      margin-right: 1.5rem;
    }
    ${breakpoints.max.md} {
      width: 100%;
    }
	`}

  ${breakpoints.max.md} {
    flex-basis: auto;
    margin-top: 0;
    order: 1;
    margin-bottom: 1rem;
    justify-content: flex-start;
    text-align: left;
    width: auto;
    max-width: calc(100% - 5rem);
  }
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  color: ${colors.red};
  font-size: ${fonts.size.xs};
  margin-top: 0.4rem;
`;
export const TooltipWrapper = styled.div`
  align-self: flex-start;
  font-size: 0;
  margin-top: 1rem;
  margin-left: 1rem;
  flex: 0 0 3rem;
  ${breakpoints.max.md} {
    margin-left: 0;
    margin-top: 0;
    order: 2;
  }
`;

// Petit hack pour donner la bordure rouge à l'input si erreur
export const FieldWrapper = styled.div<{ variant: EFieldContainerVariant }>`
  display: flex;
  flex-direction: column-reverse;
  max-width: calc(60% - 3rem);
  position: relative;

  ${(props) =>
    props.variant === EFieldContainerVariant.Small &&
    `
    overflow: hidden;
    flex: 0 0 auto;
    max-width: 100%;
    flex-shrink: 1;
	`}

  ${(props) =>
    props.variant === EFieldContainerVariant.BigLabel &&
    `
    max-width: calc(30% - 3rem);
	`}

  ${(props) =>
    props.variant === EFieldContainerVariant.FullWidth &&
    `
    max-width: 100%;
    width: 100%;
	`}
  & > ${StyledErrorMessage} + input,
  & > ${StyledErrorMessage} + textarea,
  & > ${StyledErrorMessage} + select {
    border-color: ${colors.red};
  }

  ${breakpoints.max.md} {
    width: 100%;
    max-width: 100%;
    order: 3;
  }
`;
