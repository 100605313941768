import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home } from "./pages";
import "./theme/reset.css";
import { Footer } from "./components";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
      </Switch>

      <Footer
        links={[
          {
            title: "Agréé AMF",
          },
          {
            url: "https://www.cncef.org/mentions-legales/",
            title: "Mentions légales",
          },
          {
            url: "https://www.​cncef.​org/contact/",
            title: "Contact",
          },
        ]}
      />
    </Router>
  );
}
