import styled from "styled-components";
import { colors, fonts } from "../../theme";

export const StyledListItem = styled.li`
  display: flex;
  font-size: ${fonts.size.m};
  line-height: 1.4;
  list-style: none;
  &:before {
    content: "";
    display: block;
    flex: 0 0 0.5rem;
    width: 0.5rem;
    height: 0.5rem;
    background-color: ${colors.red};
    margin-top: 1rem;
    margin-right: 1.5rem;
  }
  & + & {
    margin-top: 1.1rem;
  }
  a {
    text-decoration: underline;
  }
`;
