import { css } from "styled-components";
import { colors, fonts, breakpoints } from "../theme";

const inputStyles = () => css`
  background-color: ${colors.white};
  border: 0.2rem solid ${colors.greyBorder};
  color: ${colors.text};
  font-size: ${fonts.size.s};
  font-weight: 500;
  border-radius: 2px;
  padding: 1rem 0.9rem;
  width: 35.2rem;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  height: auto;
  line-height: 1.45;
  transition: all 0.2s:

  &:hover,
  &:hover:focus,
  &:focus {
    background-color: ${colors.white};
    color: ${colors.text};
  }

  &:focus,
  &:hover:focus {
    outline: none;
    border-color: ${colors.primary};
  }

  &::placeholder {
    color: ${colors.grey};
    font-weight: 400;
  }

  &:disabled,
  &:disabled:hover {
    background-color: ${colors.grayLight};
    border-color: ${colors.grayLighter};
  }

  ${breakpoints.max.md} {
    width: 100%;
    max-width: 100%;
  }

  ${(props) =>
    props.$error &&
    `
      color: ${colors.red};
			border-color: ${colors.red};
		`}

`;

export default inputStyles;
