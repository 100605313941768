import React from "react";
import { Tooltip } from "../../components";
import { EFieldContainerVariant, EInputSize } from "../../types";
import {
  Wrapper,
  Label,
  LabelWrapper,
  StyledErrorMessage,
  TooltipWrapper,
  FieldWrapper,
} from "./styled-components";

interface Props {
  children: React.ReactNode;
  tooltip?: string;
  label?: string | React.ReactNode;
  name: string;
  size?: EInputSize;
  required?: boolean;
  variant?: EFieldContainerVariant;
}
const FieldContainer: React.FC<Props> = ({
  children,
  tooltip,
  label = "",
  required = false,
  name,
  variant = EFieldContainerVariant.Default,
}) => {
  return (
    <Wrapper variant={variant}>
      <LabelWrapper variant={variant}>
        {label ? (
          <Label variant={variant} htmlFor={name}>
            {label}
            {required ? "\u00a0*" : ""}
          </Label>
        ) : null}
      </LabelWrapper>
      <FieldWrapper variant={variant}>
        <StyledErrorMessage component="div" name={name} />
        {children}
      </FieldWrapper>
      {tooltip ? (
        <TooltipWrapper>
          <Tooltip>{tooltip}</Tooltip>
        </TooltipWrapper>
      ) : null}
    </Wrapper>
  );
};

export default FieldContainer;
