import React from "react";
import { Logo as logoSrc } from "../../assets/svg";

import {
  StyledFooter,
  Wrapper,
  LogoWrapper,
  Logo,
  Menu,
  MenuItem,
  MenuLinkItem,
} from "./styled-components";
import { Container } from "../../components";

interface IMenuLink {
  title: string;
  url?: string;
}
interface Props {
  links: IMenuLink[];
}

const Footer: React.FC<Props> = ({ links }) => {
  return (
    <StyledFooter>
      <Container>
        <Wrapper>
          <LogoWrapper href="/">
            <Logo src={logoSrc} />
          </LogoWrapper>
          <Menu>
            {links.map((item, index) =>
              item.url ? (
                <MenuLinkItem
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  href={item.url}
                >
                  {item.title}
                </MenuLinkItem>
              ) : (
                <MenuItem key={index}>{item.title}</MenuItem>
              )
            )}
          </Menu>
        </Wrapper>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
