export const getStripePublicKey = (entity: string) => {
  let key: string | null | undefined = null;
  switch (entity) {
    case "CNCEF":
      key = process.env.REACT_APP_STRIPE_KEY_CNCEF;
      break;
    case "CNCEF Patrimoine":
      key = process.env.REACT_APP_STRIPE_KEY_PATRIMOINE;
      break;
    case "CNCEF Crédit":
      key = process.env.REACT_APP_STRIPE_KEY_CREDIT;
      break;
    case "CNCEF Immobilier":
      key = process.env.REACT_APP_STRIPE_KEY_IMMOBILIER;
      break;
    case "CNCEF Assurance":
      key = process.env.REACT_APP_STRIPE_KEY_ASSURANCE;
      break;
    case "CNCEF France M&A":
      key = process.env.REACT_APP_STRIPE_KEY_FRANCE;
      break;
  }
  return key;
};
