import React from "react";
import {
  EContainerVariant,
  ESectionSize,
  ESectionVariant,
  ETextVariant,
} from "../../types";
import { Content, TitleWrapper, Wrapper } from "./styled-components";
import { Text, Container } from "../../components";

interface Props {
  title?: string;
  tooltip?: string;
  children: React.ReactNode;
  variant?: ESectionVariant;
  size?: ESectionSize;
  titleVariant?: ETextVariant.Title1 | ETextVariant.Title3;
}

const Section: React.FC<Props> = ({ title = "", children }) => {
  return (
    <Wrapper>
      <Container variant={EContainerVariant.Small}>
        <TitleWrapper>
          <Text variant={ETextVariant.Body2}>{title}</Text>
        </TitleWrapper>
        <Content>{children}</Content>
      </Container>
    </Wrapper>
  );
};

export default Section;
